import { useNavigate, useSearchParams } from "react-router-dom";
import { Fragment, useEffect } from "react";
import { toast } from 'react-toastify';

import Tab, { TabProps } from "../../molecules/Tab";

import PageForm from "../../templates/PageForm";
import { computed, useSignal } from "@preact/signals-react";
import { ErrorField } from "../../../types/ErrorField";
import useFetch from "../../../hooks/useFetch";
import { BsBuilding } from "react-icons/bs";
import { LuBadgeDollarSign } from "react-icons/lu";
import { TbLetterA, TbLetterV } from "react-icons/tb";

import { removeErrorField } from "../../../utils/formErrorUtils";
import PartnerFormTab1 from "./PartnerFormTab1";

const BASE_URL = `${process.env.REACT_APP_REGISTRATION_API_URL}/legal-persons`;
const API_KEY = process.env.REACT_APP_REGISTRATION_API_KEY || '';

const PROFILE_TO_TAB_MAPPING: Record<string, TabProps> = {
    'RECIPIENT': {
        id: 'tab-5',
        title: 'Beneficiário',
        index: 3,
        icon: LuBadgeDollarSign,
    },
    'ISSUER': {
        id: 'tab-4',
        title: 'Emitente PJ',
        index: 4,
        icon: BsBuilding,
    },
    'GUARANTOR-WHOLESALE': {
        id: 'tab-3',
        title: 'Aval. atacado',
        index: 2,
        icon: TbLetterA,
    },
    'GUARANTOR-RETAIL': {
        id: 'tab-2',
        title: 'Aval. varejo',
        index: 1,
        icon: TbLetterV,
    },
};

type Form = {
    govTaxId: string;
    fantasyName: string;
    legalName: string;
    postalCode: string;
    street: string;
    streetType: string;
    number: string;
    complement: string;
    district: string;
    cityId: string;
}

const PartnerPageForm = () => {

    const navigate = useNavigate();
    const isSendingForm = useSignal<boolean>(false);
    const errorFields = useSignal<ErrorField[]>([]);
    const apiVersion = useSignal('');
    const isLoadingForm = useSignal<boolean>(false);
    const provinces = useSignal<any[]>([]);
    const cities = useSignal<any[]>([]);

    const form = useSignal<Form>({
        govTaxId: '',
        fantasyName: '',
        legalName: '',
        postalCode: '',
        street: '',
        streetType: '',
        number: '',
        complement: '',
        district: '',
        cityId: '',
    });

    const tabItems: TabProps[] = [
        { id: "tab-1", title: 'Dados cadastrais', index: 0 },
    ];

    const [searchParams] = useSearchParams();
    const tabIndex: number = parseInt(searchParams.get('tab') || "0");
    const profiles = searchParams.get('profiles') || '';
    const profilesArray = profiles.split(',');

    profilesArray.forEach(profile => {
        const tabItem = PROFILE_TO_TAB_MAPPING[profile];
        if (tabItem) {
            tabItems.push(tabItem);
        }
    });

    useEffect(() => {
        fetchProvinces();
    }, []);

    const fetchProvinces = async () => {
        isLoadingForm.value = true;
        try {
            const response = await fetch(`${process.env.REACT_APP_GEOLOCATION_URL}/provinces`, {
                headers: {
                    "x-api-key": process.env.REACT_APP_GEOLOCATION_API_KEY || "",
                },
            });
            provinces.value = await response.json();
        } catch (error) {
            toast.error("Erro ao carregar os estados.");
        }
        isLoadingForm.value = false;
    };

    const fetchCities = async (province: string) => {
        isLoadingForm.value = true;
        try {
            const response = await fetch(`${process.env.REACT_APP_GEOLOCATION_URL}/cities?province_initial=${province}&fields=id,name,ibgeCode`, {
                headers: {
                    "x-api-key": process.env.REACT_APP_GEOLOCATION_API_KEY || "",
                },
            });
            isLoadingForm.value = false;
            cities.value = await response.json();
        } catch (error) {
            toast.error("Erro ao carregar as cidades. Tente novamente!");
        }
        isLoadingForm.value = false;
    };

    const cancelForm = () => {
        navigate("/registration/partners/0/100/id/ASC/");
    };

    const submitForm = async () => {
        isSendingForm.value = true;

        try {
            const response = await fetch(`${BASE_URL}`, {
                method: 'POST',
                headers: {
                    'x-api-key': API_KEY,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(form.value),
            });

            if (response.ok) {
                toast.success('Parceira cadastrada com sucesso!');
                navigate(-1);
            } else {
                const { errors, message } = await response.json();
                toast.error(message);
                errorFields.value = errors ? errors.map((error: any) => ({ fieldName: error.field, message: error.message })) : [];
            }
        } catch (error) {
            toast.error("Ocorreu um erro na comunicação com o servidor!\nTente novamente mais tarde.");
        }
        isSendingForm.value = false;
    };

    const { isLoading, data, error, isTokenError } = useFetch(`${BASE_URL}/page-form`, [{ key: 'x-api-key', value: API_KEY }]);

    if (isTokenError) {
        navigate("/denied");
    }

    if (error) {
        navigate("/network-error");
    }

    const setTabIndex = (tabIndex: number) => {
        navigate(`?tab=${tabIndex}&profiles=${profiles}`);
    };

    const saveButtonDisable = computed(() => {
        return (isLoading || isSendingForm.value || (form.value.govTaxId === '' && form.value.fantasyName === '' &&
            form.value.legalName === '' && form.value.postalCode === '' && form.value.street === '' && form.value.streetType === '' &&
            form.value.number === '' && form.value.complement === '' && form.value.district === '' && form.value.cityId === ''));
    });

    const changeForm = (field: string, value: string) => {
        form.value = {
            ...form.value,
            [field]: value,
        };
        removeErrorField(errorFields.value, field);
    };

    return (
        <PageForm
            pageTitle={"Nova parceira"}
            apiVersion={apiVersion.value}
            isLoading={isLoading || isSendingForm.value || isLoadingForm.value}
            breadcrumb={[
                { label: "Listagem de parceiras", url: "/registration/partners/0/100/id/ASC/" },
                { label: "Nova parceira", url: "#" },
            ]}
            controlSlot={(
                <div className="flex space-x-4 justify-end m-2">

                    <button
                        className='text-black bg-slate-200 px-4 py-2 hover:bg-slate-400 rounded'
                        onClick={cancelForm}
                        title="Cancelar registro"
                        disabled={isLoading || isSendingForm.value}
                    >
                        Cancelar
                    </button>
                    <button
                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-800 disabled:bg-gray-300 disabled:text-gray-500"
                        onClick={submitForm}
                        title="Salvar registro"
                        disabled={saveButtonDisable.value}
                    >
                        Salvar
                    </button>
                </div>
            )}
        >
            <Fragment>
                <Tab tabItems={tabItems} tabIndex={tabIndex} setTabIndex={setTabIndex}></Tab>
                <div id="tab-content" className="overflow-y-auto">
                    {tabIndex == 0 &&
                        <PartnerFormTab1
                            key="tab1"
                            form={form.value}
                            errorFields={errorFields.value}
                            changeForm={changeForm}
                            provinces={provinces.value}
                            cities={cities.value}
                            changeProvince={fetchCities}

                        />
                    }

                    {tabIndex == 1 &&
                        <div className="p-4 m-4"
                            id="retail-guarantor">
                            Avalista varejo
                        </div>}

                    {tabIndex == 2 &&
                        <div className="p-4 m-4"
                            id="wholesale-guarantor">
                            Avalista atacado

                        </div>}

                    {tabIndex == 3 &&
                        <div className="p-4 m-4"
                            id="recipient">
                            Beneficiário
                        </div>}

                    {tabIndex == 4 &&
                        <div className="p-4 m-4"
                            id="issuer">
                            Emitente PJ
                        </div>}
                </div>
            </Fragment>

        </PageForm>
    );
};


export default PartnerPageForm;