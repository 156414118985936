import { useSignal } from "@preact/signals-react";
import { ErrorField } from "../../../types/ErrorField";
import TextEdit from "../../molecules/TextEdit";
import SelectTextField, { OptionProps } from "../../molecules/SelectTextField";
import { getErrorMessage } from "../../../utils/formErrorUtils";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { GrMapLocation } from "react-icons/gr";
import MaskEdit from "../../organisms/MaskEdit";

interface Props {
    form: any;
    errorFields: ErrorField[];
    changeForm: (field: string, value: string) => void;
    provinces: any[];
    cities: any[];
    changeProvince: (province: string) => void;
}

const streetTypes = [
    "Rua", "Alameda", "Área", "Avenida", "Campo",
    "Chácara", "Colônia", "Condomínio", "Praça", "Rodovia",
];

const PartnerFormTab1: React.FC<Props> = ({ form, errorFields, changeForm,
    provinces = [], cities = [], changeProvince }) => {
    const provincesOpt = useSignal<OptionProps[]>([]);
    const citiesOpt = useSignal<OptionProps[]>([]);
    const isAddressEnabled = useSignal(false);
    const isLoadingCities = useSignal(false);

    const handlePostalCodeChange = async (value: string) => {
        changeForm("postalCode", value);
        value = value.replace(/[^0-9]/g, "");

        if (value.length == 0) {
            isAddressEnabled.value = false;
            changeForm("street", "");
            changeForm("streetType", "");
            changeForm("district", "");
            changeForm("complement", "");
            changeForm("province", "");
            changeForm("ibge", "");
            return;
        }
        if (value.length === 8) {
            isAddressEnabled.value = true;
            try {
                const response = await fetch(`https://viacep.com.br/ws/${value}/json/`);
                const data = await response.json();

                if (data.erro) {
                    toast.error("CEP não encontrado. Verifique o número digitado.");
                    return;
                }

                changeForm("streetType", data.logradouro.split(" ")[0] || "");
                changeForm("street", data.logradouro || "");
                changeForm("district", data.bairro || "");
                changeForm("complement", data.complemento || "");
                changeForm("province", data.uf || "");
                changeForm("ibge", data.ibge || "");

                changeProvince(data.uf || "");

            } catch (error) {
                toast.error("Erro ao buscar o CEP. Tente novamente mais tarde.");
            }
        } else {
            isAddressEnabled.value = false;
        }
    };

    useEffect(() => {
        if (form.postalCode.length === 8) {
            isAddressEnabled.value = true;
        }
    }, []);

    useEffect(() => {
        provincesOpt.value = provinces.map((province: any) => ({
            label: province.name,
            value: province.initials,
        }));

    }, [provinces]);

    useEffect(() => {
        isLoadingCities.value = true;
        citiesOpt.value = cities.map((city: any) => ({
            label: city.name,
            value: city.id,
        }));

        if (form.ibge) {

            const cityId = cities.filter((city: { ibgeCode: any; }) => city.ibgeCode === form.ibge);

            changeForm("cityId", cityId.length > 0 ? cityId[0].id : "");
        }
        isLoadingCities.value = false;
    }, [cities]);

    return (
        <div className="p-4 m-4">
            <div className="grid grid-cols-12 gap-4">
                <MaskEdit
                    id="govTaxId"
                    label="CNPJ"
                    mask="__.___.___/____-__"
                    replacement={{ '_': /\d/ }}
                    className="col-span-2"
                    value={form.govTaxId}
                    onChange={(e) => changeForm("govTaxId", e.target.value)}
                    errorMessage={getErrorMessage(errorFields, "govTaxId")}
                />
                <TextEdit
                    id="legalName"
                    label="Razão Social"
                    className="col-span-5"
                    value={form.legalName}
                    onChange={(e) => changeForm("legalName", e.target.value)}
                    errorMessage={getErrorMessage(errorFields, "legalName")}
                />
                <TextEdit
                    id="fantasyName"
                    label="Nome Fantasia"
                    className="col-span-5"
                    value={form.fantasyName}
                    onChange={(e) => changeForm("fantasyName", e.target.value)}
                    errorMessage={getErrorMessage(errorFields, "fantasyName")}
                />
            </div>

            <div className="grid grid-cols-6 gap-4 mt-6">
                <MaskEdit
                    id="postalCode"
                    label="CEP"
                    mask="_____-___"
                    replacement={{ '_': /\d/ }}
                    prefix={<GrMapLocation />}
                    labelClassName="ml-6"
                    value={form.postalCode}
                    onChange={(e) => handlePostalCodeChange(e.target.value)}
                    errorMessage={getErrorMessage(errorFields, "postalCode")}
                />
            </div>

            <div className="grid grid-cols-12 gap-4 mt-6">
                <SelectTextField
                    id="streetType"
                    name='streetType'
                    label="Tipo de logradouro"
                    value={form.streetType}
                    className="col-span-2"
                    items={streetTypes.map(st => ({ label: st, value: st }))}
                    onChange={(value) => changeForm("streetType", value)}
                    errorMessage={getErrorMessage(errorFields, "streetType")}
                    disabled={!isAddressEnabled.value}
                />
                <TextEdit
                    id="street"
                    label="Logradouro"
                    maxLength={180}
                    value={form.street}
                    className="col-span-5"
                    onChange={(e) => changeForm("street", e.target.value)}
                    errorMessage={getErrorMessage(errorFields, "street")}
                    disabled={!isAddressEnabled.value}
                />
                <TextEdit
                    id="number"
                    label="Número"
                    maxLength={10}
                    value={form.number}
                    className="col-span-1"
                    onChange={(e) => changeForm("number", e.target.value)}
                    errorMessage={getErrorMessage(errorFields, "number")}
                    disabled={!isAddressEnabled.value}
                />
                <TextEdit
                    id="district"
                    label="Bairro"
                    maxLength={120}
                    value={form.district}
                    className="col-span-4"
                    onChange={(e) => changeForm("district", e.target.value)}
                    errorMessage={getErrorMessage(errorFields, "district")}
                    disabled={!isAddressEnabled.value}
                />
            </div>

            <div className="grid grid-cols-12 gap-4 mt-6">
                <TextEdit
                    id="complement"
                    label="Complemento"
                    maxLength={150}
                    value={form.complement}
                    className="col-span-4"
                    onChange={(e) => changeForm("complement", e.target.value)}
                    errorMessage={getErrorMessage(errorFields, "complement")}
                    disabled={!isAddressEnabled.value}
                />
                <SelectTextField
                    id="province"
                    name='province'
                    label="UF"
                    value={form.province}
                    items={provincesOpt.value}
                    className="col-span-3"
                    onChange={(e) => {
                        changeForm("province", e.target.value);
                        changeProvince(e.target.value);
                    }}
                    errorMessage={getErrorMessage(errorFields, "province")}
                    disabled={!isAddressEnabled.value}
                />
                <SelectTextField
                    id="cityId"
                    name='cityId'
                    label="Cidade"
                    value={form.cityId}
                    items={citiesOpt.value}
                    className="col-span-5"
                    onChange={(e) => changeForm("cityId", e.target.value)}
                    errorMessage={getErrorMessage(errorFields, "cityId")}
                    disabled={!isAddressEnabled.value || isLoadingCities.value}
                />
            </div>

            <pre className="bg-gray-100 p-4 mt-8 text-xs">
                {JSON.stringify(form, null, 2)}
            </pre>

        </div>
    );
};

export default PartnerFormTab1;
