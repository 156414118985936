import React, { ReactElement, createElement } from 'react';
import { IconType } from 'react-icons';

export type TabProps = {
  id: string;
  title: string;
  badge?: string | number;
  icon?: IconType;
  index: number;
  disabled?: boolean;
};

interface TabsComponentProps {
  tabItems: TabProps[];
  tabIndex: number;
  setTabIndex?: (index: number) => void; // Function to update active tab
}

const Tab: React.FC<TabsComponentProps> = ({ tabItems, tabIndex, setTabIndex = () => { } }) => {
  return (
    <div className="border-b border-gray-200 dark:border-gray-700">
      <ul id='tab-list' role='tablist' className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
        {tabItems.map((tabItem) => (
          <TabItem
            key={tabItem.id}
            title={tabItem.title}
            badge={tabItem.badge}
            icon={tabItem.icon}
            disabled={tabItem.disabled}
            index={tabItem.index}
            activeIndex={tabIndex}
            setTabIndex={setTabIndex} // Function to change tabs
          />
        ))}
      </ul>
    </div>
  );
};

type TabItemProps = {
  title: string;
  badge?: string | number;
  icon?: IconType;
  disabled?: boolean;
  index: number;
  activeIndex: number;
  setTabIndex: (index: number) => void;
};

const TabItem: React.FC<TabItemProps> = ({ title, badge, icon, index, activeIndex, disabled, setTabIndex }) => {
  const tabClasses = `inline-flex items-center justify-center p-4 border-b-2 rounded-t-lg hover:text-blue-600 hover:border-blue-600 
    ${index === activeIndex ? 'border-blue-600 text-blue-600' : 'border-transparent'} 
    ${disabled ? 'cursor-not-allowed text-gray-400' : ''}`;

  let iconElement: ReactElement | null = null;
  if (icon) {
    iconElement = createElement(icon);
  }

  return (
    <li className="mr-2">
      <button
        type="button"
        className={tabClasses}
        onClick={() => !disabled && setTabIndex(index)} // Update tab index on click
        disabled={disabled}
      >
        {iconElement && <span className="mr-2">{iconElement}</span>}
        {title}
        {badge && <span className="ml-2 text-xs font-medium rounded p-1 bg-gray-200 text-gray-500">{badge}</span>}
      </button>
    </li>
  );
};

export default Tab;
