import { FC } from "react";
import InputFrame from "../atoms/InputFrame";

export type OptionProps = {
    value: string;
    label: string;
}

type SelectTextFieldProps = {
    id: string;
    name: string;
    label: string;
    errorMessage: string;
    onChange: (e: any) => void;
    items: OptionProps[];
    value: string;
    emptyValue?: string;
    emptyLabel?: string;
    disabled?: boolean;
    className?: string;
}

const SelectTextField: FC<SelectTextFieldProps> = ({ id, name, label, errorMessage, items,
    value, onChange, emptyValue = '', emptyLabel = 'Selecione um registro...', disabled = false, className }) => {

    const error = Boolean(errorMessage);
    const borderColor = error ? "border-red-600" : "border-gray-300";


    return (
        <InputFrame label={label} errorMessage={error ? errorMessage : undefined} className={className}>
            <select id={id}
                name={name}
                value={value}
                onChange={onChange}
                disabled={disabled}
                className={`block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 ${borderColor} appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer disabled:opacity-50 disabled:cursor-not-allowed`}
            >
                <option value={emptyValue}>{emptyLabel}</option>

                {items.map((item, index) => (
                    <option key={index} value={item.value}>
                        {item.label}
                    </option>
                ))}
            </select>
        </InputFrame>
    );
}

export default SelectTextField;