import React, { InputHTMLAttributes, ReactNode, useRef } from 'react';
import { useMask } from '@react-input/mask';
import InputFrame from '../atoms/InputFrame';

interface MaskEditProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'prefix'> {
    label: string;
    errorMessage?: string;
    className?: string;
    labelClassName?: string;
    prefix?: ReactNode;
    mask: string;
    replacement: {
        [key: string]: RegExp;
    };
}

const MaskEdit: React.FC<MaskEditProps> = ({
    prefix,
    label,
    errorMessage,
    className,
    labelClassName,
    mask,
    replacement,
    ...restProps
}) => {

    const inputRef = useMask({
        mask,
        replacement,
    });

    let textColor = "text-gray-900";
    let focusColor = "focus:border-blue-600";

    if (errorMessage) {
        textColor = "text-red-700";
        focusColor = "focus:border-red-600";
    }

    return (
        <InputFrame
            label={label}
            errorMessage={errorMessage}
            className={className}
            prefix={prefix}
            textColor={textColor}
            labelClassName={labelClassName}
        >
            <input
                ref={inputRef}
                className={`block py-2.5 px-0 w-full text-sm ${textColor} bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 ${focusColor} peer disabled:opacity-50 disabled:cursor-not-allowed`}
                placeholder=" "
                {...restProps}
            />
        </InputFrame>
    );
};

export default MaskEdit;